import React, { PureComponent, SyntheticEvent, ImgHTMLAttributes, ReactNode } from "react"
import Layout from "../components/layout"
import styled from "styled-components"

import Modal from "../components/modal"
import Section, {SubSection, Header, SubHeader, Divider} from "../components/section/section"
import { WindowLocation } from "@reach/router";

import theme from '../theme'

const ImageGrid = styled.div`
  display: grid;
  grid-template-areas: 'a1 a2 a2' 'b1 b1 b2' 'c1 c2 c2';
  grid-gap: 1rem;

  @media(max-width: 600px) {
    grid-template-areas: 'a1' 'a2' 'b1' 'b2' 'c1' 'c2';
  }
`

interface IndexPageProps {
  location: WindowLocation,
  children: ReactNode[]
}

interface IndexPageState {
  isMobile: boolean,
  toggledImage: string | undefined
}

export default class Index extends PureComponent<IndexPageProps, IndexPageState> {
  constructor(props: IndexPageProps) {
    super(props)

    this.toggleImage = this.toggleImage.bind(this)

    this.state = {
      isMobile: false,
      toggledImage: undefined
    }
  }

  componentDidMount() {
    this.setState({
      isMobile: typeof window !== "undefined" && window.innerWidth <= 768
    })
  }

  toggleImage = (event: SyntheticEvent<ImgHTMLAttributes<HTMLImageElement>>) : void => {
    this.setState({
      toggledImage: event.currentTarget ? event.currentTarget.src : undefined
    })
  }

  render() {
    const modalizedImage = this.state.toggledImage ? (
      <Modal handleClose={this.toggleImage}>
        <img 
          src={this.state.toggledImage} 
          alt="Toggled"
          style={{
            margin: "0",
            maxHeight: "90vh",
            maxWidth: "99vw", 
            objectFit: "contain",
            padding: "40px"
          }}/>
      </Modal>
    ) : null

    return (
      <Layout location={this.props.location}>
        <Section width="100%" margin="0" backgroundColor="whitesmoke">
          <SubSection>
            <Header>Header</Header>
            <SubHeader>
              Subheader
            </SubHeader>
            <Divider themeColor={theme.palette.primary.main}/>
            <Section alignItems="center" justifyContent="center">
              Section
            </Section>
          </SubSection>
        </Section>
        <Section width="100%" margin="0 0 40px 0" position="relative">
          <SubSection>
            <Header>Header</Header>
            <SubHeader>
              Subheader
            </SubHeader>
            <Divider themeColor={theme.palette.primary.main}/>
          </SubSection>
        </Section>
        {modalizedImage}
      </Layout>
    )
  }
}